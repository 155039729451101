import React from 'react';
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { DemoHeader } from "../commonComponents"

const styles = {
    fontContact: {
        color: "#fff",
        fontFamily: "DM Sans",
    },
    alignContent: {
        display: "flex",
        alignItems: "center",
    },
}

const myHTML = `
    <script src="https://api1.irisagent.com/v1/chatbot/bundle"></script>
  <script type='text/javascript'>
    initIrisAgentChatbot( "c639ce07dcd4aa37ac49adcd23a63e8e22a80711f5f9297cf093abf30d2d" );
  </script>
`;

export default function DropboxDemo() {
    return (
        <div>
            <DemoHeader
                customer="DROPBOX"
            />
            <Helmet>
                <meta name={`robots`} content={`noindex, nofollow`} />
                <link
                    rel="canonical"
                    href="https://irisagent.com/dropbox-demo/"
                />
            </Helmet>
            <section className="inner-page" style={{paddingTop: "30px", paddingLeft: "20px"}}>
                <div dangerouslySetInnerHTML={{ __html: myHTML }} />
            </section>

        </div>
    )
}
